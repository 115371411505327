import React from 'react';

import Layout from '../components/Layout';

import Sidebar from '../components/Sidebar';
import Classes from './index.module.css';

const book = () => (
  <Layout>
    <Sidebar showTabs="false" />
    <div className="container-fluid p-0">
      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-center"
        id="happy"
      >
        <div className="w-100">
          <h3 className="mb-5">
            <span className={Classes.blink}>[NEW]</span>
            <span style={{ textDecoration: 'underline' }}>
              The Road to Allah مدارج السالكين{' '}
            </span>
          </h3>
          <p>
            https://MyIslam.net
            <h2>Practicing The way of the travelers (Madarej Assalekeen)</h2>
            <h3>Introduction</h3>
            Many times, we get into heedlessness of the hereafter. Many times,
            Allah will wake us up from this heedlessness to be conscious of the
            remaining time and work hard towards the goal of winning paradise.
            However, since we do not have a guide, a map, or what we need to do
            to stay moving forward, our enemies of devils, desires, and
            distractions always win over us and bring us back to heedlessness,
            again and get us busy with this life. Ibn ElQayeem realized this
            struggle and he wrote a book that will take the travelers to Allah
            step by step through over sixty steps starting from the step of
            Consciousness. I went through those steps multiple times, but I
            could not find a real practical application for it that is
            appropriate to the 20th century. The unfortunate events of the year
            2020 of Covid-19, Fires in California, Layoffs, the stock market
            going down, and all the panic and chaeos were one of the reasons to
            wake me up, again, to look into my life and get back, again on the
            road to Allah following the travelers steps as a guide. While, I
            have multiple audio sessions, before on YouTube
            (https://www.youtube.com/playlist?list=PLNT8BTFvQYHLmHSeXABFEYS-hZ9IwGdWI
            ) and https://MyIslam.net, I received the feedback of a need for a
            written format. Also, another feedback was the length of the
            Madarej. Therefore, I decided to summarize it to the action and keep
            references to the long audio explanations. The new series in shaa
            Allah will be posted to https://myislam.net/The_Road_to_Allah/ and
            https://www.youtube.com/playlist?list=PLNT8BTFvQYHJ9RBJR54wk1j_O4DaQt-Zq
            <h3>The step of Consciousness:</h3>
            We get into the consciousness state many times. It could be
            triggered by an event, such as a calamity, a bounty, a supplication
            that is accepted, a lecture, Ramadan, or a death for a close person.
            When we are in this state, we need to keep it. Imam Ibn ElQayeem
            said that we need to take an action. The Consciousness will lead to
            realizing that we are behind in preparing for the real life. It will
            lead to looking into sins and understanding the danger in it. Then
            plan to make up for the shortcomings. This will lead to the
            following state, The Idea. What are the pillars of
            vigilance/Consciousness? أركان اليقظة 1-مطالعة الجناية Consciousness
            of bounties of Allah. The heart will notice the grace of Allah.
            2-لحظ القلب إلى النعمة Consciousness of your Sins 3-معرفة الزيادة و
            النقصان من الأيام. Consciousness of time. Realizing the increase and
            decrease of days. More details can be found at
            https://youtu.be/gLzpcem5wpA The step of The Idea The step of The
            Idea is about the vision and deciding on our goal. Our goal should
            be the hear after and winning Paradise. We have one Goal is “to
            please Allah, alone.”
            <h3>The state of Commitment</h3>
            The state of Commitment is the state that we should commit to travel
            to Allah in spite of all the challenges. Ibn ElQayeem is comparing
            this trip to the physical trip to a physical destination. First you
            need to wake up, decide your destination, get the map and GPS, and
            commit to travel. The step of Self Evaluation: O you who have
            believed, fear Allah . And let every soul look to what it has put
            forth for tomorrow – and fear Allah . Indeed, Allah is Acquainted
            with what you do. Omar ben Elkhatab said “Examine yourself, today,
            before you get examined. Weight your deeds, today, before it is
            weighed. It is easier today to examine yourself and prepare for the
            presentation day.” And recited the verse that can be translated as:
            “That Day(The day of judgement) , you will be exhibited [for
            judgment]; not hidden among you is anything concealed. “ This is the
            most important step. The moment you stop self-evaluation, you go
            back to heedlessness. Again, Ibn ElQayeem gives an example from the
            real life. If you are traveling, you need to check how much fuel you
            have and git rid of excess weight. When we travel by air, we make
            sure we have the correct weight. I used to have 3 different scales
            to make sure I will not be charged for extra weight. The same when
            we travel to Allah, we get rid of things that are not relevant to
            our goal. You need to do question the act before, in the middle, and
            after. Is it going to help you in your travel to Allah, or get you
            away. You are always comparing the bounties of Allah versus your
            sins. You are conscious of the time and its value. What are the
            signs of reaching this state: 1. You are always checking the act you
            are going to do against your goal and asking yourself, “Will this
            help me to get closer to Allah.” 2. During the act, you keep asking
            your self are you still doing this only for the sake of Allah? 3.
            Finally, after the act you ask yourself What are the shortcomings I
            had and how can I be better. Always, keeping track of sins so that
            you can treat them as sicknesses. How to Practice? Some scholars
            said write the bounties of Allah in one side and your sins on the
            other. Others said take a time of the day to question yourself.
            Allah said: "وَبِالْأَسْحَارِ هُمْ يَسْتَغْفِرُونَ " “And in the
            hours before dawn they would ask forgiveness” (51:18). Some said to
            use technology and record to their phone. Ancestors had other
            approaches. One of the ancestors used to simulate the grave and put
            himself in a box and question himself similar to the day of judgment
            and ask to get back to life. Then he opens the chest and praise
            Allah that he still has the time. Try different things and see what
            works for you, the most important thing is to keep doing it. I try
            my best to present every act to the goal I defined during the state
            of Idea “Pleasing Allah, ” before, during, and after, as much as I
            can. It varies according to the level of my consciousness and the
            state of my heart. Right now you do not need to react, just ask
            yourself “Will/did this get me to my goal?” During Ramadan, I used
            one of the daily tracker sheets that I used to put on the fridge and
            I mark the items at the end of the day. Currently, I have a list on
            my phone that I scan before I sleep, to see if I forgot something
            through the day. Actually, I found myself doing the same with my
            diet. I used to follow a balanced diet and count calories, before,
            each meal and at the end of the day. I used to have a tracking sheet
            for my weightlifting exercises to make sure I am progressing well
            and to pick up the right weight. At work, I do self-evaluation for
            myself and my team every 3 months, twice a year, and every week
            during our one on one meetings. Also, in software development we use
            Scrum. Which is basically about continuous evaluation and adjusting
            to make sure we are on the right path. We usually go through the
            goals and evaluate our performance against it. Self-Critical is one
            of the most important competencies at work. We use it every day in
            our daily life. Why can’t we use it for hereafter? It is very hard
            to question and evaluate yourself, but it is important to measure
            your performance towards your goal and to see if you are on the
            right track. After a while, it will become a habit to question your
            actions without papers. This is the state that Ibn ElQayeen said you
            will have the internal voice that evaluates you (your internal
            conscious). The most important thing is to start slowly. Do not
            assume that you will change 100% in one day. Just keep tracking your
            progress to make sure you are progressing and not heedless. It is
            one of the famous tricks of the devil is to get us into so many
            change which are more than we can take and of course we give up
            quickly. I like one of the Body building approaches where the author
            suggests keeping track of the daily progress to make sure you are
            progressing. It is recommended to start slowly with weights so that
            you do not get injured and stop practicing. Here is a snapshot of
            the daily progress sheet As you can se he suggests a plan and report
            the actual progress. In addition, he has notes at the end for his
            feelings. I had a friend who has been following a similar approach
            in religion. He is improving every while. For example, he used to
            stay after Fajr for few minutes until he was able to stay until
            Shorooq and is still doing that for the past 15 years. Also, he
            started to go to Eetekaf and stay in Mekah inside the mosque the
            last 5 days. Today, he stays the entire month of Ramadan for 2
            years. Originally, he started to do the Eetekaf in the close mosque
            in his city, first. He has been always going up in his relation with
            Allah. I have seen others they get awake because of an event and
            they stay in the mosque all day and they spend the night in Qiam.
            However, after few weeks the just stop. The prophet told us that the
            best of the worship is the continuous, even if it is little. That is
            why I advise to go slowly and I hope that we get the reward with the
            intention that we have to keep walking to Allah and keep improving.
            So, if someone had just started praying the 5 daily prayers, it does
            not make sense to add all sunnan and Qiam all night … Add extras
            over obligatory as you progress forward. The same as adding weights.
            If you started by lifting 200 LBs for the first time in your life,
            it is a guarantee that you will not lift again for a few years.
            Let’s say we have a plan similar to the following for a person who
            has not been able to pray Fajr on time for a while: Date Plan Actual
            Notes 9/20/2020 Pray Fajr before Shorooq I missed by few minutes I
            need to setup another way to wake me up. I will ask a friend to call
            me. 9/21/2020 Pray Fajr before Shorooq I was able to wake up but I
            went back to sleep I will ask my friend to stay with me on the phone
            until I make Wudu 9/25/2020 Pray Fajr before Shorooq I was able to
            pray Fajr on time but it was very close to SHorooq I will try to
            wake up 15 minutes earlier, tomorrow 8/12/2021 Pray Fajr in
            congregation Prayed Fajr in congregation Missed First Rakaa.
            Tomorrow I will wake up earlier Self is like a wormhole do not try
            to open and clean. Ibn ElQayeem said it is like a mountain of
            obstacles so start walking and whenever you face an obstacle, treat
            it. So just start walking on the road and face challenges as the
            face you. The final goal ice to switch yourself from “النفس الامارة
            بالسوء” the Evil Self to the Blaming self “النفس اللوامة” that Allah
            swore by it in the Quran. That you keep blaming yourself of
            shortcomings. When we get to the stage of repentance we will try to
            cleanse and change, but for now we need to move from heedlessness of
            time and our main goal to be conscious of Sins, bounties, and time.
            If I spent an hour watching Netflix, movie, game, or even just
            surfing on YouTube, I should feel some guilt. If you feel guilty
            this is Consciousness. If you feel this is normal, this is
            heedlessness. I gave and example of things that are not obvious
            haram, but if you feel normal to watch adultery sites without guilt,
            then this is deep sleep in heedlessness that is waiting for the
            event of Consciousness. Keep listening to lectures that talk about
            the hereafter. There are some good questions for self-evaluation in
            the following site?
            https://en.islamway.net/article/12165/a-detailed-self-evaluation-guide
            1. Please, listen to https://youtu.be/myGZ0EgvCfU 2. Try to look for
            other lectures about self-evaluation (محاسبة النفس) a.
            https://muqith.wordpress.com/you-can-write/ b.
            https://en.islamway.net/article/12165/a-detailed-self-evaluation-guide
            3. Try to find a way for self-evaluation. a. Daily: Her is an
            example of daily self-evaluation
            https://MyIslam.net/static/daily-deeds-management-list.pdf b.
            Monthly/Yearly:
            https://en.islamway.net/article/12165/a-detailed-self-evaluation-guide
            REFERENCES: • [English - YouTube PlayList] Practicing Madarej
            Assalekeen - تطبيق كتاب مدارج السالكين – retrieved from
            https://www.youtube.com/playlist?list=PLNT8BTFvQYHLmHSeXABFEYS-hZ9IwGdWI
            • [English] Purifying our Hearts اصلاح القلوب retrieved from
            https://myislam.net/hearts/ • [English] Book of Madarij Assalekeen -
            كتاب مدارج السالكين retrieved from
            https://iloveallah.net/static/Madarij-as-Salikeen-Imam-Ibn-Qayim-Al-Jawziyah-ENG.pdf
            • [Arabic] Book of Madarij Assalekeen - كتاب مدارج السالكين ابن قيم
            الجوزية – Retrieved from
            https://ar.wikisource.org/wiki/%D9%85%D8%AF%D8%A7%D8%B1%D8%AC_%D8%A7%D9%84%D8%B3%D8%A7%D9%84%D9%83%D9%8A%D9%86
            • [English] Book of Al-Wabil al-Sayyib [Ibn al Qaiem] - الوابل الصيب
            من الكلم الطيب retrieved from
            https://www.amazon.com/Ibn-Qayyim-al-Jawziyya-Invocation-God/dp/B00NPO7BSC
            • [Arabic] الوابل الصيب من الكلم الطيب ابن قيم الجوزية retrieved
            from
            https://ar.wikisource.org/wiki/%D9%83%D8%AA%D8%A7%D8%A8_%D8%A7%D9%84%D9%88%D8%A7%D8%A8%D9%84_%D8%A7%D9%84%D8%B5%D9%8A%D8%A8
            • [Arabic] كتاب مدارج السالكين ابن قيم الجوزية retrieved from
            https://www.amazon.com/%E2%80%AB%D9%85%D8%AF%D8%A7%D8%B1%D8%AC-%D8%A7%D9%84%D8%B3%D8%A7%D9%84%D9%83%D9%8A%D9%86-%D9%85%D9%86%D8%A7%D8%B2%D9%84-%D9%86%D8%B3%D8%AA%D8%B9%D9%8A%D9%86-Arabic-ebook/dp/B07771R9B5
            • [Arabic Only - Audio] Madarej Assalekeen - شرح كتاب مدارج السالكين
            - محمد حسين يعقوب - طريق الإسلام retrieved from
            https://ar.islamway.net/collection/1807/%D8%B4%D8%B1%D8%AD-%D9%83%D8%AA%D8%A7%D8%A8-%D9%85%D8%AF%D8%A7%D8%B1%D8%AC-%D8%A7%D9%84%D8%B3%D8%A7%D9%84%D9%83%D9%8A%D9%86
            • https://sunnah.com/ • https://www.dorar.net/hadith • Quran
            SmartSearch
            https://www.islamicity.org/quransearch/?AspxAutoDetectCookieSupport=1
          </p>
        </div>
      </section>
    </div>
  </Layout>
);

export default book;
